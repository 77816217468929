import _cache from "../utils/_cache"
import _cache_key from "./_cache_key";

let _cache_city = {
    key: _cache_key.selected_city,

    set: function (v) {
        _cache.session_set(this.key, v);
    },

    get: function () {
        return _cache.session_get(this.key);
    },

    has: function () {
        return _cache.session_has(this.key);
    },

    empty: function () {
        return _cache.session_empty(this.key);
    }
};

//导出
export default _cache_city
