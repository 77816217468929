
//定义缓存函数
let _cache = {

  //存session
  session_set : function (k, v, s = 1) {
    v = (s === 0) ? v : JSON.stringify(v)
    window.sessionStorage.setItem(k, v);
  },

  //取session
  session_get : function (k, s = 1) {
    let v = window.sessionStorage.getItem(k)
    return (s === 0) ? v : JSON.parse(v);
  },

  //查session
  session_has : function (k) {
    return window.sessionStorage.hasOwnProperty(k);
  },

  //验
  session_empty : function (k){
    return !(window.sessionStorage.hasOwnProperty(k) && window.sessionStorage.getItem(k));
  },

  //删session
  session_del : function (k) {
    window.sessionStorage.removeItem(k);
  },

  //清session
  session_clear : function (){
    window.sessionStorage.clear();
  },

  //存local
  local_set : function (k, v, s = 1) {
    v = (s === 0) ? v : JSON.stringify(v)
    window.localStorage.setItem(k, v);
  },

  //取local
  local_get : function (k, s = 1) {
    let v = window.localStorage.getItem(k);
    return (s === 0) ? v : JSON.parse(v);
  },

  //查local
  local_has : function (k) {
    return window.localStorage.hasOwnProperty(k);
  },

  //验
  local_empty : function (k){
    return !(window.localStorage.hasOwnProperty(k) && window.localStorage.getItem(k));
  },

  //删local
  local_del : function (k) {
    window.localStorage.removeItem(k);
  },

  //清local
  local_clear : function (){
    window.localStorage.clear();
  }

};

//导出
export default _cache
