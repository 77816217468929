
//路由组
import _config from "../common/_config";

let _url = {
  //首页
  home : _config.prefix + '/home',
  about: _config.prefix + '/about',
  operate: _config.prefix + '/operate',
  contact: _config.prefix + '/contact',
  auto: _config.prefix + '/auto',
  news: _config.prefix + '/news',
  info: _config.prefix + '/info',
  verification: _config.prefix + '/verification',
  brand_sign: _config.prefix + '/brand_sign',
  //404
  nofound: '*'
};

//获取路由

//导出
export default _url
