
let _string = {

    trim : function (str) {
        return str.replace(/(^\s*)|(\s*$)/g, '');
    },

    ltrim : function (str) {
        return str.replace(/(^\s*)/g, '');
    },

    rtrim : function (str) {
        return str.replace(/(\s*$)/g, '');
    },

    trim_separator : function (str, separator) {
        let regular = new RegExp('(^' + separator + '+)|(' + separator + '+$)', 'g');
        return str.replace(regular, "");
    },

    ltrim_separator : function (str, separator) {
        let regular = new RegExp('(^' + separator + '+)', 'g');
        return str.replace(regular, "");
    },

    rtrim_separator : function (str, separator) {
        let regular = new RegExp('(' + separator + '+$)', 'g');
        return str.replace(regular, "");
    }
};


export default _string
