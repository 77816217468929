
let _cache_key = {

    //activity
    activity_key : 'activity_info',
    //品牌车型
    activity_car: 'activity_car',
    //城市
    selected_city: 'selected_city'
};


export default _cache_key
