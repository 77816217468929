
import _object from "../utils/_object";
import _string from "../utils/_string";

let _router = {

    //注册路由函数
    _register_rule : function (rules, path, name, component, prefix, redirect = '', children = []) {
        //去除间隔符
        component = _string.trim_separator(component, '/')
        //额外参数
        let meta = {};
        let rule = {};
        rule.path = path;
        rule.name = name;
        rule.component = (resolve) => require([`../views/${prefix}/${component}`], resolve);
        rule.meta = meta;
        if (_object._length(children) > 0)
            rule.children = children;
        if (redirect)
            rule.redirect = redirect;
        rules.push(rule);
    },

    //判断手机PC
    _isMobile: function () {
        return /Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent);
    }
}

export default _router;
