import Vue from 'vue'
import VueRouter from 'vue-router'

//
import _router from "../common/_router";
import _url from "./_url";
import _cache_city from "../cache/_cache_city";
import getUrlKey from "../utils/_geturl";

Vue.use(VueRouter)

let web_prefix = 'pc'
web_prefix = _router._isMobile()?'m':'pc';

const routes = [];

//首页
_router._register_rule(routes, _url.home, 'home', 'home/home', web_prefix,0);
//关于
_router._register_rule(routes, _url.about, 'about', 'about/about', web_prefix,0);
//代运营
_router._register_rule(routes, _url.operate, 'operate', 'operate/operate', web_prefix,0);
//联系
_router._register_rule(routes, _url.contact, 'contact', 'contact/contact', web_prefix,0);
//车展
_router._register_rule(routes, _url.auto, 'auto', 'auto/auto', web_prefix,0);
//新闻
_router._register_rule(routes, _url.news, 'news', 'news/news', web_prefix,0);
//详情
_router._register_rule(routes, _url.info, 'info', 'news/info', web_prefix,0);
//核销
_router._register_rule(routes, _url.verification, 'verification', 'verification/verification', web_prefix,0);
//单品报名
_router._register_rule(routes, _url.brand_sign, 'brand_sign', 'cars/brand_sign', web_prefix,0);



const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

// 路由拦截
router.beforeEach((to, from, next) => {
  let city_id = getUrlKey('city_id');
  if(city_id>0){
    _cache_city.set(parseInt(city_id));
  }
  let selectd_city_id = parseInt(_cache_city.get());
  if(!selectd_city_id){
    _cache_city.set(1301);
  };
  //继续
  next();
});

export default router
