

//项目配置
const _config = {

    //项目路由前缀
    prefix: '',
    //活动ID
    activity_id: 'activity_id',
    //渠道
    channel: 'channel',
    //提交接口
    common_submit: '/call/import/common',
    //二级接口
    common_supply: '/call/import/common_supply'
}

export default _config
